import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
// import IntegerField from '@/shared/fields/integer-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import ImagesField from '@/shared/fields/images-field';
import JsonField from '@/shared/fields/json-field';

function label(name) {
  return i18n(`entities.country.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  // id: new IntegerField('id', label('id')),
  name: new JsonField('name', label('name'), {}),
  center: new JsonField('center', label('center'), {}),
  iso2: new StringField('iso2', label('iso2'), { max: 2 }),
  iso3: new StringField('iso3', label('iso3'), { max: 3 }),

  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class CountryModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
