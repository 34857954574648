//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CountryModel } from '@/modules/country/country-model';
import { i18n } from '@/i18n';

const { fields } = CountryModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.center,
  fields.iso2,
  fields.iso3,
]);

export default {
  name: 'app-country-form-page',
  props: ['id'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },
  computed: {
    ...mapGetters({
      record: 'country/form/record',
      findLoading: 'country/form/findLoading',
      saveLoading: 'country/form/saveLoading',
      
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
  },
  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record);

    if (!this.isEditing) {
      this.model.name = {
        en: undefined,
        ar: undefined,
      }
      this.model.center = {
        latitude: undefined,
        longitude: undefined,
      }
    } else if (!this.model.center) {
      this.model.center = {
        latitude: undefined,
        longitude: undefined,
      }
    }
  },
  methods: {
    ...mapActions({
      doFind: 'country/form/doFind',
      doNew: 'country/form/doNew',
      doUpdate: 'country/form/doUpdate',
      doCreate: 'country/form/doCreate',
    }),
    i18n(code) {
      return i18n(code);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model);
      if (this.isEditing) {
        return await this.doUpdate({
          id,
          values,
        });
      } else {
        let record = await this.doCreate(values);
        return record;
      }
    },
  },
};
